import { Box, Stack, Typography, Grid } from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React from "react";
import { Trans } from "@lingui/macro";
import Container from "./Container";
import SecondaryButton from "./SecondaryButton";

function HeroLandingV2({
  title,
  description,
  handleOnclickInfo,
  ButtonRequestInfoText = <Trans>Request info</Trans>,
  backgroundImage,
  kindImage,
}) {
  const renderContent = (_title, _description) => {
    const currentTitle = title ?? _title;
    const currentDescription = description ?? _description;

    return (
      <Container>
        <Grid
          container
          pt={{ xs: 4.3, sm: 6, md: 6, lg: 8 }}
          spacing={5.5}
          flexDirection={{ xs: "column-reverse", lg: "row" }}>
          <Grid
            item
            pt={{ xs: "24px !important", sm: 6, md: 0 }}
            xs={12}
            lg={5}
            display="flex"
            alignContent="center"
            justifyContent={{ xs: "center", sm: "center", lg: "flex-end" }}>
            <Box sx={{ width: { xs: "100%", sm: "70%", lg: "100%" } }}>
              <img width="100%" height="100%" style={{ objectFit: "contain" }} alt="background_image" src={backgroundImage} />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            alignContent="center"
            lg={7}
          >
            <Stack spacing={2}>
              <Box width={{ xs: "160px", sm: "250px" }}>
                <img src={kindImage} alt="kind" width="100%" style={{ objectFit: "contain" }} />
              </Box>
              <Stack spacing={2}>
                {currentTitle && (
                  <Typography variant="h1" fontSize={{ xs: 36, sm: 48, lg: 60 }}>
                    {currentTitle}
                  </Typography>
              )}
                {currentDescription && (
                  <Typography variant="description">
                    {currentDescription}
                  </Typography>
              )}
                <Box display={{ xs: "flex", lg: "inline-block" }} pt={{ xs: 1, sm: 0 }} justifyContent="flex-start">
                  <SecondaryButton
                    handleOnclickInfo={handleOnclickInfo}
                    text={ButtonRequestInfoText}
                />
                </Box>
              </Stack>
            </Stack>
          </Grid>

        </Grid>
      </Container>
    );
  };

  return (
    <Box>
      {renderContent()}
    </Box>
  );
}

export default HeroLandingV2;
